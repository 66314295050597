import * as React from 'react';
import { dataGridStyles } from './dataGridStyles';
import { 
  DataGridPro,
  GridToolbar,
   } from '@mui/x-data-grid-pro';


export default function CurrentItemsTable(props) {
    // !new code

    const visibleColumns = {
      id: false,
      barCode: false,
    }
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentItems  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'barCode', headerName: 'Bar Code', width:200, headerAlign: 'center', align: 'center'},
      // {field: 'accountName', headerName: 'Account Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'productNum', headerName: 'Product Number', width:200, headerAlign: 'center', align: 'center', editable: false},
      {field: 'productName', headerName: 'Product Name', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'itemCost', headerName: 'Item Cost', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'size', headerName: 'Size', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'weight', headerName: 'Weight', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'manufacture', headerName: 'Manufacture', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdAt', headerName: 'Created Date', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'creator', headerName: 'Created By', width:200, headerAlign: 'center', align: 'center',  editable: false},
    //   {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
    //   ]},

      ], 
      rows: props.currentItemsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
        sx={dataGridStyles.root}
        pagination
        initialState={{
          pageSize: 10,
          columns: {
            columnVisibilityModel: visibleColumns,
          },
          pinnedColumns: {left: ['productNum']},
          }}
        slots={{toolbar: GridToolbar}}

      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentItems} 

      />
    </div>

  );
};