import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext.js';
import DragDrop2 from "./DragDrop2.js";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageSnackBar from './MessageSnackBar.js';

import { 
    Button, 
    Grid,
    Paper ,
    Typography } from '@mui/material';

const ASNManagement = (props) => {
    //SECTION USER CONTEXT and HEADERS
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    // !SECTION

    //SECTION Message Bar Controls
      const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
      const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
      const [ messageSeverity, setMessageSeverity ] = React.useState('success');
      const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
      };
      //!SECTION

    //SECTION Component Controls
    const itemMessage = 'ASN Management';

    const toggleItems = () => {
      if (viewItems){
          setViewItems(false);
          setViewItemsMessage(`${itemMessage}`);
          // console.log("user data", userData.name)
      }else{
          // getWarehouseList(userData.email)
          setViewItems(true);
          setViewItemsMessage(`Hide ${itemMessage}`);
      };
  } ;

    const [ viewItems, setViewItems ] = React.useState(false);
    const [ viewItemsMessage, setViewItemsMessage ] = React.useState(`View ${itemMessage}`);

    //!SECTION
    


  //SECTION - URLS
    const shipmentStatusUrl = `/Shipments/updateShipmentStatus` // updates the shipment status from carrier
    const shippedReceivedUrl = `/Shipments/shippedReceivedItemsProcessing` //updates the shipment to inbound
    const orderInStockDiscrepanciesUrl = `/Shipments/orderinstockdiscrepancy` //reviews if the item was in stock when ordered

    //SECTION - Put data processing

    //This takes a URL and parameters and sends a put request to the backend
    const handlePutDataProcessing = (url, parameters) => {
      axios
        .put(
          url,
          parameters,
          { headers: headers }
        )
        .then(function async(response) {
          setMessageBarMessage(response.data.message);
          setMessageBarOpen(true);
          setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };
    //!SECTION


  return (  

      <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ alignContent: "center", border: 0 }}
      >
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
        >
          <Button 
          onClick={toggleItems}
          startIcon={<ManageAccountsIcon size="large" />}
          >
            {" "}
            {viewItemsMessage}
          </Button>

          {/* Component Items */}
          {viewItems &&
          <>
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
            
          {/* Drag and Drop 2 */}
          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ alignContent: "center", border: 0 }}
          >
          <DragDrop2
           />
          </Grid>
          
          <Grid container marginTop={5} marginBottom={5} sx={{justifyContent:'center', alignItems:'center', alignContent:'center', pb:10, pt:5,  border:1, borderStyle: 'dotted', borderRadius: '5px'  }}>
          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ justifyContent: "center", alignItems:'center', alignContent:'center', border: 0, marginTop: 0, marginBottom: 10 }}
          >
            <Typography variant="h4" align="center" >
              Shipment and Replenishment Processing
            </Typography>
          </Grid>

          {/* UpDate shipment Status */}
          <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{  border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
          <Button onClick={() => handlePutDataProcessing(shipmentStatusUrl, {})} variant='contained' align="center" component="label">
            Update Shipment Status
          </Button>
          </Grid>
          
          {/* Received shipped item Inbounded */}

          <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
          <Button onClick={() => handlePutDataProcessing(shippedReceivedUrl, {})} variant='contained' component="label">
            Received Item Processing
          </Button>
          </Grid>


          {/* Received shipped item Inbounded */}

          <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
          <Button onClick={() => handlePutDataProcessing(orderInStockDiscrepanciesUrl, {})} variant='contained' component="label">
            Order Stock Discrepancies
          </Button>
          </Grid>


          </Grid>

        </>
    }
        </Paper>
      </Grid>
  </>


  );
};
export default ASNManagement;