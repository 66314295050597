import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


export const UserContext = createContext();
export const useUser = () => useContext(UserContext);
export const UserProvider = ({ children }) => {

  const { user, isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          // console.log("accessToken: ", accessToken);
          // console.log("user: ", user);
          // console.log("is Authenticated: ", isAuthenticated);
          const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          };
          sessionStorage.setItem('headers', JSON.stringify(headers));
          // const userInfo = user;
          await axios.get(`LogIn/okta/login/${user.email}`, { headers: headers })
            .then(function (response) {
            const additionalData = response.data.message;
            const combinedData = { ...user, ...additionalData };
            setUserData(combinedData);
            // console.log("userData: ", combinedData);
            localStorage.setItem('userData', JSON.stringify(combinedData));
            })
            .catch(function (error) {
              console.log("Error fetching user data: ", error.response.data.detail);
              alert(error.response.data.detail);
              alert("Contact support: info@edonconnect.tech");
            }
            );
          // update the platform user sign on date
          await axios.put(`LogIn/platformusersignondate/${user.email}`, { headers: headers });
          // console.log("new_sign_on_date: ", new_sign_on_date);
        } catch (error) {
          alert("Contact support: info@edonconnect.tech");
          console.error('Error fetching user data:', error); //error message
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  // use this to check if the token has expired test this later
  const checkTokenExpiration = async () => {
    const expirationTime = sessionStorage.getItem('tokenExpiration');
    if (expirationTime && Date.now() > expirationTime) {
      try {
        const accessToken = await getAccessTokenSilently();
        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
        const newExpirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const headers = {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        sessionStorage.setItem('headers', JSON.stringify(headers));
        sessionStorage.setItem('tokenExpiration', newExpirationTime);
        console.log('Token refreshed successfully');
      } catch (error) {
        console.error('Error refreshing token:', error);
        if (error.error === 'login_required' || error.error === 'consent_required' || error.error === 'interaction_required') {
          console.error('Token renewal failed, redirecting to login:', error);
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        }
      }
    }
  };



  // useEffect(() => {
  //   console.log("useEffect in User: ", user);
  //   console.log("useEffect in User redirect: ", process.env.REACT_APP_REDIRECT_URL );
  // }, [user]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }


  return (
    <UserContext.Provider value={ userData }>
      {children}
    </UserContext.Provider>
  );
};