import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import EdonLogo from './edonLogoWhite.png'
import WALogo from './WA_logo.png'
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BiotechIcon from '@mui/icons-material/Biotech';
import PageviewIcon from '@mui/icons-material/Pageview';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MapsHomeWork from '@mui/icons-material/MapsHomeWork';
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from '@mui/material/Paper';
import HeaderMenus from './Components/HeaderMenus.js';
import DragDrop from './Components/DragDrop.js';
import RegionalFileDrop from './Components/RegionFileDrop';
import DirtyFileGrid from './Components/DirtyFileGrid';
import FieldSelections from './Components/FieldSelections.js';
import UserDirtyFilesGrid from './Components/UserDirtyFilesGrid.js';
import DropDownSelector from './Components/DropDownSelector.js';
import AccountDropDown from './Components/AccountDropDown.js'
import MessageSnackBar from './Components/MessageSnackBar';
import WarehouseFileDrop from './Components/WarehouseFileDrop.js';
import FlexLogo from './flexe_Logo.png';
import MicrosoftLogo from './MicrosoftLogo.jpeg'
import AcmeLogo from './img/acme.jpeg'
import Copyright from './Components/Copyright.js';
import Unauthorized from './Components/Unauthorized.js';
import ASNManagement from './Components/ASNManagement.js';



// const mdTheme = createTheme();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



export default function DataUpload(props) {
  const userData = useContext(UserContext)

  const pageTitle = "Data Upload";

  function TestData(props) {
    return (
      <Grid
        container
        marginTop={5}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          p: 5,
          border: 1,
          borderStyle: "dotted",
          borderRadius: "5px",
        }}
      >
        <Button onClick={loadtestdata} variant="contained">
          Test Data
          {testDataSpinner && (
            <CircularProgress sx={{ margin: 1 }} color="inherit" size={20} />
          )}
        </Button>
      </Grid>
    );
  }

  const [saveMessage, setSaveMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [testDataSpinner, setTestDataSpinner] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION



  //SECTION Axios Load test data 
  //!Depricate
  //!Depricate
  //!Depricate
  const loadtestdata = () => {
    //setLoadingTestData(true);
    const fileName = "metro5ExampleDemandOrders.csv";
    const userid = userData.email;
    setTestDataSpinner(true);
    axios
      .get(`/testfileloaded/${fileName}/${userid}`)
      .then(function (response) {
        //console.log("Button", response.data)
        //const marketCount = response.data.metroNames.length
        setSnackbarOpen(true);
        setSaveMessage(response.data);
        setTestDataSpinner(false);
        //handleGoToOptimization();
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  var testcolumnNames = [
    { field: "orderNumber", headerName: "Order Number", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "state", headerName: "State" },
    { field: "ZIP", headerName: "Zip" },
    { field: "itemNumber", headerName: "Item Number", flex: 1 },
    { field: "unitCost", headerName: "Unit Cost" },
    { field: "itemQuantity", headerName: "Quantity" },
    { field: "orderDate", headerName: "Order Date" },
  ];

  var testrows = [
    {
      id: 1,
      orderNumber: "1-KIR0EQV",
      address: "4435 SHALLOWFORD RD",
      city: "ROSWELL",
      state: "GA",
      ZIP: 30328,
      itemNumber: "I-464333344",
      unitCost: 125,
      itemQuantity: 1,
      orderDate: "1/14/21",
    },
    {
      id: 2,
      orderNumber: "1-KMCDS61",
      address: "4460 COBB PKWY NW",
      city: "ACWORTH",
      state: "GA",
      ZIP: 30101,
      itemNumber: "I-464333211",
      unitCost: 125,
      itemQuantity: 1,
      orderDate: "1/24/21",
    },
    {
      id: 3,
      orderNumber: "1-LGNKA02",
      address: "2810 OLD MILTON PKWY",
      city: "ALPHARETTA",
      state: "GA",
      ZIP: 30009,
      itemNumber: "I-464333211",
      unitCost: 235,
      itemQuantity: 1,
      orderDate: "3/13/21",
    },
    {
      id: 4,
      orderNumber: "1-LXZY4MF",
      address: "3550 CENTERVILLE HWY",
      city: "SNELLVILLE",
      state: "GA",
      ZIP: 30039,
      itemNumber: "I-464333212",
      unitCost: 345,
      itemQuantity: 2,
      orderDate: "1/12/21",
    },
    {
      id: 5,
      orderNumber: "1-QMSJIKN",
      address: "2697 OLD PEACHTREE RD",
      city: "DULUTH",
      state: "GA",
      ZIP: 30097,
      itemNumber: "I-464333260",
      unitCost: 625,
      itemQuantity: 1,
      orderDate: "3/24/21",
    },
  ];
  //!SECTION

  const [fileRecommendations, setFileRecommendations] = React.useState([]);
  const [fileDataTypes, setFileDataTypes] = React.useState([]);
  const [fileExampleColumnNames, setFileExampleColumnNames] =
    React.useState(testcolumnNames);
  const [fileExampleRows, setFileExampleRows] = React.useState(testrows);
  const [columnNamesList, setColumnNamesList] = React.useState(["No Data"]);
  const [fileName, setFileName] = React.useState("");
  const [viewFieldSelection, setViewFieldSelections] = React.useState(false);
  const [viewDirtyFileGrid, setViewDirtyFileGrid] = React.useState(false);
  const [viewDragDrop, setViewDragDrop] = React.useState(false);
  const [viewUserUploads, setViewUserUploads] = React.useState(false);
  const [viewTestData, setViewTestData] = React.useState(false);
  const [dragDropMessage, setDragDropMessage] = React.useState(
    "UpLoad Your Demand File"
  );
  const [testDataMessage, setTestDataMessage] =
    React.useState("View Data Example");
  const [userUploadsMessage, setUserUploadsMessage] = React.useState(
    "View Your Uploaded Files"
  );
  const [missingValues, setMissingValues] = React.useState([]);
  const [errorFieldSelection, setErrorFieldSelection] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [userDirtyFileUploadInfo] = React.useState(
    []
  );

  //SECTION Upload User Demand file
  const handleUploadData = (response) => {
    //! check for field errors MOve this logic to the 
    const errorFieldSelectionIndex =
      response.data.columnNamesList[response.data.columnNamesList.length - 1]
        .value;
    var i = 0;
    var errorFields = errorFieldSelection;
    for (const column in response.data.recommendations) {
      if (
        response.data.recommendations[column][0] === errorFieldSelectionIndex
      ) {
        errorFields[i] = true;
      }
      i = i + 1;
    }
    setErrorFieldSelection(errorFieldSelection);
    // console.log("Error Fields", errorFields)

    var message =
      "Your Raw Data has been saved.  Review and verify the required fields below.";
    setSnackbarOpen(true);
    setSaveMessage(message);
    setFileRecommendations(response.data.recommendations);
    setFileDataTypes(response.data.dataTypes);
    setMissingValues(response.data.missingValues);
    setFileExampleRows(response.data.exampleRows);
    setFileExampleColumnNames(response.data.exampleColumnNames);
    setColumnNamesList(response.data.columnNamesList);
    setFileName(response.data.fileName);
    setViewFieldSelections(true);
    setViewDirtyFileGrid(true);
    setViewDragDrop(false);
  };

  const closeWindows = () => {
    setViewDragDrop(false);
    setDragDropMessage("UpLoad Your Demand Files");
    setViewFieldSelections(false);
    setViewDirtyFileGrid(false);
    setViewTestData(false);
    setTestDataMessage("UpLoad Test Data");
  };

  //!SECTION


  //SECTION Select the Regional File
  const [viewRegionSelectMessage, setViewRegionSelectMessage] =
    React.useState("Select Region File");
  const [viewRegionSelect, setViewRegionSelect] = React.useState(false);

  const toggleRegionSelect = () => {
    if (viewRegionSelect) {
      setViewRegionSelect(false);
      setViewRegionSelectMessage("Select Region File");
    } else {
      setViewRegionSelect(true);
      setViewRegionSelectMessage("Hide Region Select");
      getRegionFileList();
    }
  };
  //!SECTION


  //! Items for Dropdown
  // Get the regional file list from Database
  //SECTION Axios get region file list
  const getRegionFileList = () => {
    const user = userData.email;
    axios
      .get(`/regionalfilelist/${user}`)
      .then(function (response) {
        setRegionFileList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Regional files are unavailable. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });
  };
  //!SECTION

  //! put the selected regional file in this variable
  // This is sent down to the Field Selections component for cleaning
  const [ regionalFileName, setRegionalFileName ] = React.useState("Zip_Metros_Default.csv");

  //! props to send down to the Drop Down Selector Component
  //List to send down. Must have an ID!
  const [regionFileList, setRegionFileList] = React.useState([
    {
      id: 1,
      label: "Default Regions",
      value: "Zip_Metros_Default.csv",
    },
  ]);
  //handler to send down
  const handleSelectedRegionFile = async (filename) => {
    setRegionalFileName(filename); // the component will send back the name of the file chosen
  };

  //Variables to send down
  const dropDownTitles = {
    dropDownTitle: "Select A Regional File",
    dropDownButtonTitle: "Load Regional File",
    dropDownLabel: "Select A Regional File",
    dropDownHelperText: "A Default will be use if not selected.",
    dropDownAlertMessage: "Warehouse File is now " ,
  };
  //!SECTION


//SECTION Upload Regional File 
  const [viewRegionDropMessage, setRegionDropMessage] = React.useState(
    "Upload Your Region File"
  );
  const [viewRegionDrop, setViewRegionDrop] = React.useState(false);
  const toggleRegionDrop = () => {
    if (viewRegionDrop) {
      setViewRegionDrop(false);
      setRegionDropMessage("Upload Your Region File");
    } else {
      setViewRegionDrop(true);
      setRegionDropMessage("Hide Upload");
    }
  };
  //!SECTION

//SECTION Upload Demand File
  const toggleDragDrop = () => {
    if (viewDragDrop) {
      setViewDragDrop(false);
      setDragDropMessage("UpLoad Your Demand File");
      setViewFieldSelections(false);
      setViewDirtyFileGrid(false);
    } else {
      setViewDragDrop(true);
      setDragDropMessage("Hide Upload");
    }
  };
  //!SECTION
  
  //SECTION Upload Warehouse File
  const [viewWarehouseDropMessage, setWarehouseDropMessage] = React.useState(
    "Upload A Warehouse File"
  );

  const [viewWarehouseDrop, setViewWarehouseDrop] = React.useState(false);



  const toggleWarehouseDrop = () => {
    if (viewWarehouseDrop) {
      setViewWarehouseDrop(false);
      setWarehouseDropMessage("Upload A Warehouse File");
    } else {
      setViewWarehouseDrop(true);
      setWarehouseDropMessage("Hide Upload");
    }
  };
  //!SECTION



  //SECTION Load test data
  const toggleTestData = () => {
    if (viewTestData) {
      setViewTestData(false);
      setTestDataMessage("View Data Example");
      setViewDirtyFileGrid(false);
      setViewFieldSelections(false);
    } else {
      setViewTestData(true);
      setTestDataMessage("Hide Data Example");
      setViewDirtyFileGrid(true);
    }
  };
//!SECTION


  //SECTION Axios View Uploaded Files
  const getUploadeFilesinfo = () => {
    console.log("Get Uploaded Files Info");
    // const userid = userData.email;
    //!This needs to be updated to use the new API
    // axios
    //   .get(`/slist/${userid}/${accountCode}`)
    //   .then(function (response) {

    //     setUserDirtyFileUploadInfo(response.data);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     setMessageBarMessage(`Oh no! Your demand files are currently unavailable. Please try again or email info@edonconnect.tech for help. ${error.message}`);
    //     setMessageBarOpen(true);
    //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };
  
  const handleHideFieldSelections = () => {
    setViewFieldSelections(false);
    setViewDirtyFileGrid(false);
  };
  const toggleUploadedFiles = () => {
    if (viewUserUploads) {
      setViewUserUploads(false);
      setUserUploadsMessage("View Your Uploaded Files");
    } else {
      setViewUserUploads(true);
      setUserUploadsMessage("Hide Your Uploaded Files");
      getUploadeFilesinfo(); //get the file info
    }
  };
//!SECTION



//SECTION View Settings
  const [ viewSettings, setViewSettings ] = React.useState(false);
  const [ viewSettingMessage, setSettingsMessage ] = React.useState('View Settings');
  const toggleSettings = () => {
    if (viewSettings) {
      setViewSettings(false);
      setSettingsMessage("View Settings");
      // setViewFileDeleteButton(false);
    } else {
      setViewSettings(true);
      setSettingsMessage("Hide Settings");
      //!Close subWindows
        //Region Select
      setViewRegionSelect(false);
      setViewRegionSelectMessage("Select Region File");
        //Close Upload Regional File 
      setViewRegionDrop(false);
      setRegionDropMessage("Upload Your Region File");
        // Close Upload Warehouse File
        setViewWarehouseDrop(false);
        setWarehouseDropMessage("Upload A Warehouse File");
    }
  };


//!SECTION

//SECTION  View Select Account
  const closeAllWindows = () => {
    //! Close Upload
    setViewDragDrop(false);
    setDragDropMessage("UpLoad Your Demand File");
    setViewFieldSelections(false);
    setViewDirtyFileGrid(false);
    //!Close Test Data
    setViewTestData(false);
    setTestDataMessage("View Data Example");
    setViewDirtyFileGrid(false);
    setViewFieldSelections(false);
    //!Close View Uploaded Demand Files
    setViewUserUploads(false);
    setUserUploadsMessage("View Your Uploaded Files");
    //! Close Settings
    setViewSettings(false);
    setSettingsMessage("View Settings");
  };


  const [ accountName, setAccountName ] = React.useState(userData.company);
  const [ accountCode, setAccountCode ] = React.useState(userData.accountCode);
  const [ memberCode, setMemberCode ] = React.useState(userData.membershipCode);

  const [ viewAccounts, setViewAccounts ] = React.useState(false);
  const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');
  const toggleAccounts = () => {
    if (viewAccounts) {
      setViewAccounts(false);
      setAccountsMessage("View Accounts");
      // setViewFileDeleteButton(false);
    } else {
      setViewAccounts(true);
      setAccountsMessage("Hide Accounts");
      // getAccountList();
    }
  };
  //handler to send down
  const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
    setAccountName(accountname); 
    setAccountCode(accountcode);
    setMemberCode(membershipCode);
    closeAllWindows();   
  };
//!SECTION




  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />
        {/* Edon Connect Header */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          {/* Content*/}

            {memberCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {memberCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }
            {memberCode === "0xd4eed5a7" && //Warehouse Anywhere membership
            <Grid item sx={{pt:2}}>
                <img src={EdonLogo } width={200} alt="Logo" />
              </Grid>
            }
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, p: 2 }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >

                {accountCode === '0xef4b67067c' ?
                  <img src={MicrosoftLogo}  width={200} alt="Logo" /> :
                  accountCode === ('0xf14195642') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" /> : 
                  accountCode === ('0x6a6d078c6d3') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" />:  
                  <Typography align="center" variant={"h4"}>
                  </Typography> 
                  }
                  <Typography align="center" variant={"h4"}>
                    {accountName}
                  </Typography>
                  <Typography align="center" variant={"h4"}>
                    File Upload{" "}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>


                  {/* Unauthorized User */}
                  {!userData.services.includes("DU") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

                  {/* Accounts Drop Down */}
                  {userData.accountViewer && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 ,display:"flex", flexDirection:"column"}}
                >

                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                        <AccountDropDown 
                        handleSelectedAccount={handleSelectedAccount}
                        toggleAccounts={toggleAccounts}
                        headers ={props.headers}
                        user={userData} />

                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}
                </Paper>
              </Grid>
            </Grid>




            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, p: 2 }}
            >
              <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />

              {userData.userRole > 5 && userData.services.includes("DU") && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    {/* <Button onClick={toggleSavedMarketFiles}> {viewMarketMessage}</Button>  */}
                    <Button
                      onClick={toggleDragDrop}
                      startIcon={<CloudUploadIcon size="large" />}
                    >
                      {dragDropMessage}
                    </Button>
                    {viewDragDrop && (
                      <>
                    <Grid item                   
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}>
                      <Typography align="center" variant={"subtitle2"} >Using the regional file: {regionalFileName}</Typography>
                    </Grid>
                      <DragDrop
                        user={userData}
                        handleUploadData={handleUploadData}
                        accountCode={accountCode}
                      />
                      </>
                    )}
                  </Paper>
                </Grid>
              )}



            {/* Advance Shipping Notice Management */}
            {(userData.name === 'glenn.caudill@edonconnect.tech') &&
            <ASNManagement /> }


            {false &&
            <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    {/* <Button onClick={toggleSavedMarketFiles}> {viewMarketMessage}</Button>  */}
                    <Button
                      onClick={toggleDragDrop}
                      startIcon={<CloudUploadIcon size="large" />}
                    >
                      {dragDropMessage}
                    </Button>
                    {viewDragDrop && (
                      <>
                    <Grid item                   
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}>
                      <Typography align="center" variant={"subtitle2"} >Using the regional file: {regionalFileName}</Typography>
                    </Grid>
                      <DragDrop
                        user={userData}
                        handleUploadData={handleUploadData}
                        accountCode={accountCode}
                      />
                      </>
                    )}
                  </Paper>
                </Grid>
                }

              {/* Test Data Upload */}

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button
                      onClick={toggleTestData}
                      startIcon={<BiotechIcon size="large" />}
                    >
                      {testDataMessage}
                    </Button>

                    {false && <TestData />}

                    <Snackbar
                      anchorOrigin={{ horizontal: "center", vertical: "top" }}
                      open={snackbarOpen}
                      autoHideDuration={8000}
                      onClose={handleClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="success"
                      >
                        {saveMessage}
                      </MuiAlert>
                    </Snackbar>
                  </Paper>
                </Grid>

              {/* Field Selections and Data Cleaning */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                {viewFieldSelection && (
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={handleHideFieldSelections}>
                      Hide Data Cleaning
                    </Button>
                    <Grid item                   
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}>
                      <Typography align="center" variant={"subtitle2"} >Using the regional file: {regionalFileName}</Typography>
                    </Grid>
                    <FieldSelections
                      user={userData}
                      fileName={fileName}
                      fileDataTypes={fileDataTypes}
                      fileRecommendations={fileRecommendations}
                      missingValues={missingValues}
                      columnNamesList={columnNamesList}
                      errorFieldSelection={errorFieldSelection}
                      closeWindows={closeWindows}
                      regionalFileName={regionalFileName}
                      accountCode={accountCode}
                    />
                  </Paper>
                )}
              </Grid>

              

              {/* Test File Grid */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                {viewDirtyFileGrid && (
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    
                    <DirtyFileGrid
                      fileExampleColumnNames={fileExampleColumnNames}
                      fileExampleRows={fileExampleRows}
                    /> 

                  </Paper>
                )}

                {/* User File Grid */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button
                      onClick={toggleUploadedFiles}
                      startIcon={<PageviewIcon size="large" />}
                    >
                      {userUploadsMessage}
                    </Button>
                    {viewUserUploads && (
                      <UserDirtyFilesGrid
                        user={userData}
                        userDirtyFileUploadInfo={userDirtyFileUploadInfo}
                        toggleUploadedFiles={toggleUploadedFiles}

                      />
                    )}
                  </Paper>
                </Grid>

              </Grid>
              </Grid>

          {/* Settings */}
    {userData.services.includes("DU") &&
    <>
      <Grid
          container
          spacing={3}
          sx={{ alignContent: "center", border: 0, p: 2 }}
        >

      {userData.userRole > 5 && (
          <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button
                      onClick={toggleSettings}
                      startIcon={<SettingsIcon size="large" />}
                    >
                      {viewSettingMessage}
                    </Button>
          
          {viewSettings && 
          <Grid
              container
              spacing={1}
              sx={{ alignContent: "center", mt:3, border: 1, pb: 3, borderStyle: 'dotted'}}
              >
          
              {userData.userRole > 5 && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button
                      onClick={toggleRegionSelect}
                      startIcon={<LocationCityIcon size="large" />}
                    >
                      {viewRegionSelectMessage}
                    </Button>

                    {
                      viewRegionSelect && (
                        
                        <DropDownSelector
                          itemList={regionFileList}
                          handleSelectedItem={handleSelectedRegionFile}
                          dropDownTitles={dropDownTitles}
                          toggleClose={toggleRegionSelect}
                        />

                      ) // send the list of files and a handler down to the dropdown
                    }
                  </Paper>
                </Grid>
              )}
              

              {/* Drop the regional file */}
              {userData.userRole > 5 && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0, p:0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    {/* <Button onClick={toggleSavedMarketFiles}> {viewMarketMessage}</Button>  */}
                    <Button
                      onClick={toggleRegionDrop}
                      startIcon={<DriveFolderUploadIcon size="large" />}
                    >
                      {viewRegionDropMessage}
                    </Button>
                    {
                      viewRegionDrop && (
                        <>
                        <Grid item                   
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ alignContent: "center", border: 0, pt:2}}>
                          <Typography align="center" variant={"subtitle1"}>Select a file with just two columns named "ZIP"and "Metro_Name"  </Typography>
                          <Typography align="center" variant={"subtitle1"}>"ZIP": The zip Code. </Typography>
                          <Typography align="center" variant={"subtitle1"}>"Metro_Name": The name of the region the zip code is in. </Typography>
                        </Grid>

                        <RegionalFileDrop
                          user={userData}
                          toggleRegionDrop={toggleRegionDrop}
                        />
                        </>
                      ) // handleUploadData={handleUploadData}
                    }
                  </Paper>
                </Grid>
              )}

              {/* Drop a warehouse file */}
              {userData.userRole > 5 && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    {/* <Button onClick={toggleSavedMarketFiles}> {viewMarketMessage}</Button>  */}
                    <Button
                      onClick={toggleWarehouseDrop}
                      startIcon={<MapsHomeWork size="large" />}
                    >
                      {viewWarehouseDropMessage}
                    </Button>
                    {
                      viewWarehouseDrop && (
                        <WarehouseFileDrop
                          user={userData}
                          toggleWarehouseDrop={toggleWarehouseDrop}
                          accountCode={accountCode}
                        />

                      ) 
                    }
                  </Paper>
                </Grid>
              )}
              

              </Grid>
            }
            </Paper>
          </Grid>
          )}
          </Grid>
          </>
          }

            <Grid container sx={{ alignContent: "center", border: 0 }}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

